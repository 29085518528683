import { useRef, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import Select from "react-select";
import { NoProfilePicture } from './NoProfilePicture';
import { formatName } from './utils';
import { format, nextSunday, addWeeks } from "date-fns";

export const SundayProgram = ({ membersList, sustainings, releases, newMembers, issuersList }) => {
  const initialLoad = useRef(true);
  const [hymns, setHymns] = useState([]);
  const [openingHymn, setOpeningHymn] = useState(null);
  const [sacramentHymn, setSacramentHymn] = useState(null);
  const [intermediateHymn, setIntermediateHymn] = useState(null);
  const [closingHymn, setClosingHymn] = useState(null);
  const [firstSpeaker, setFirstSpeaker] = useState(null);
  const [secondSpeaker, setSecondSpeaker] = useState(null);
  const [thirdSpeaker, setThirdSpeaker] = useState(null);
  const [invocation, setInvocation] = useState(null);
  const [benediction, setBenediction] = useState(null);
  const [chorister, setChorister] = useState(null);
  const [accompanist, setAccompanist] = useState(null);
  const [presiding, setPresiding] = useState(null);
  const [conducting, setConducting] = useState(null);
  const [date, setDate] = useState(null);
  const [isFastSunday, setIsFastSunday] = useState(false);
  const [highCouncilor, setHighCouncilor] = useState('');
  const { toPDF, targetRef } = usePDF({
    filename: 'sunday-program.pdf',
    page: { 
      margin: 20,
      format: 'letter',
      orientation: 'portrait'
    }
  });

  const updateSundayProgram = async () => {
    try {
      await fetch('/api/update_sunday_program', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date,
          isFastSunday,
          openingHymnId: openingHymn?.value,
          sacramentHymnId: sacramentHymn?.value,
          intermediateHymnId: intermediateHymn?.value,
          closingHymnId: closingHymn?.value,
          firstSpeakerId: firstSpeaker?.value,
          secondSpeakerId: secondSpeaker?.value,
          thirdSpeakerId: thirdSpeaker?.value,
          invocationId: invocation?.value,
          benedictionId: benediction?.value,
          choristerId: chorister?.value,
          accompanistId: accompanist?.value,
          presidingId: presiding?.value,
          conductingId: conducting?.value,
          highCouncilor
        })
      });
    } catch (error) {
      console.error('Error updating Sunday program:', error);
    }
  };

  const handleDateChange = (option) => {
    setDate(option.value);
    updateSundayProgram();
  };

  const handleFastSundayChange = (e) => {
    const checked = e.target.checked;
    setIsFastSunday(checked);
    if (checked) {
      setIntermediateHymn(null);
      setFirstSpeaker(null);
      setSecondSpeaker(null);
      setThirdSpeaker(null);
    }
    updateSundayProgram();
  };

  const handleHighCouncilorChange = (e) => {
    setHighCouncilor(e.target.value);
    updateSundayProgram();
  };

  const fetchHymns = async () => {
    const response = await fetch('/api/list_hymns');
    const data = await response.json();
    setHymns(data);
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchHymns();
      // Set initial date to next Sunday
      const today = new Date();
      const nextSundayDate = today.getDay() === 0 ? today : nextSunday(today);
      setDate(nextSundayDate);
    };

    loadData();
    initialLoad.current = false;
  }

  // Generate next 7 Sundays
  const generateSundayOptions = () => {
    const options = [];
    const today = new Date();
    let startDate = today.getDay() === 0 ? today : nextSunday(today);
    
    for (let i = 0; i < 7; i++) {
      const sunday = i === 0 ? startDate : addWeeks(startDate, i);
      options.push({
        value: sunday,
        label: format(sunday, "EEE MMM d")
      });
    }
    return options;
  };

  const hymnSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      width: "200px",
      textAlign: "left",
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
      fontWeight: 600
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
      textAlign: "left",
      color: "black",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      minWidth: "200px",
      textAlign: "left",
      color: "black",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
      textAlign: "left",
      color: "black",
    }),
    container: (provided) => ({
      ...provided,
      width: "200px",
      position: "relative",
      textAlign: "left",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "black"
    })
  };

  const memberSelectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "15px",
      textAlign: "left",
      margin: "6px",
      width: "300px"
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #B0B5C0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      width: "300px",
      maxHeight: "200px",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "300px",
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: "2px"
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "2px", cursor: "pointer", borderBottom: "0.5px solid #e0e0e0" }}>
        <div style={{ width: "50px", height: "50px", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {data.member_photo || data.household_photo ? <img src={data.member_photo || data.household_photo} alt={data.label} style={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "5px", border: "0.5px solid #B0B5C0" }} /> : <NoProfilePicture />}
        </div>
        <span style={{ flex: 1, textAlign: "left" }}>{data.label}</span>
      </div>
    );
  };

  const handleSelectChange = (setter) => (option) => {
    setter(option);
    updateSundayProgram();
  };

  return (
    <div className="container" style={{ maxWidth: "800px", margin: "0", textAlign: "left" }}>
      <div className="section-header">Date</div>
      <Select
        value={date ? { value: date, label: format(date, "EEE MMM d") } : null}
        onChange={handleDateChange}
        options={generateSundayOptions()}
        placeholder="Select Sunday"
        styles={memberSelectStyles}
      />

      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <input
            type="checkbox"
            checked={isFastSunday}
            onChange={handleFastSundayChange}
          />
          Fast Sunday
        </label>
      </div>

      <div className="section-header">Presiding and Conducting</div>
      <Select
        value={presiding}
        onChange={handleSelectChange(setPresiding)}
        options={issuersList}
        placeholder="Presiding"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />
      <Select
        value={conducting}
        onChange={handleSelectChange(setConducting)}
        options={issuersList}
        placeholder="Conducting"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />

      <div className="section-header">Music Leaders</div>
      <Select
        value={chorister}
        onChange={handleSelectChange(setChorister)}
        options={membersList}
        placeholder="Chorister"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />
      <Select
        value={accompanist}
        onChange={handleSelectChange(setAccompanist)}
        options={membersList}
        placeholder="Accompanist"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />

      <div className="section-header">Music</div>
      <Select
        value={openingHymn}
        onChange={handleSelectChange(setOpeningHymn)}
        options={hymns}
        placeholder="Opening hymn"
        styles={hymnSelectStyles}
      />
      <Select
        value={sacramentHymn}
        onChange={handleSelectChange(setSacramentHymn)}
        options={hymns}
        placeholder="Sacrament hymn"
        styles={hymnSelectStyles}
      />
      {!isFastSunday && (
        <Select
          value={intermediateHymn}
          onChange={handleSelectChange(setIntermediateHymn)}
          options={hymns}
          placeholder="Intermediate hymn"
          styles={hymnSelectStyles}
        />
      )}
      <Select
        value={closingHymn}
        onChange={handleSelectChange(setClosingHymn)}
        options={hymns}
        placeholder="Closing hymn"
        styles={hymnSelectStyles}
      />

      <div className="section-header">Prayers</div>
      <Select
        value={invocation}
        onChange={handleSelectChange(setInvocation)}
        options={membersList}
        placeholder="Invocation"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />
      <Select
        value={benediction}
        onChange={handleSelectChange(setBenediction)}
        options={membersList}
        placeholder="Benediction"
        styles={memberSelectStyles}
        components={{ Option: CustomOption }}
      />

      <div className="section-header">High Council</div>
      <input
        type="text"
        value={highCouncilor}
        onChange={handleHighCouncilorChange}
        placeholder="High councilor name"
        style={{
          fontSize: "15px",
          padding: "8px",
          margin: "6px",
          width: "300px",
          border: "1px solid #ccc",
          borderRadius: "4px"
        }}
      />

      {!isFastSunday && (
        <>
          <div className="section-header">Speakers</div>
          <Select
            value={firstSpeaker}
            onChange={handleSelectChange(setFirstSpeaker)}
            options={membersList}
            placeholder="First speaker"
            styles={memberSelectStyles}
            components={{ Option: CustomOption }}
          />
          <Select
            value={secondSpeaker}
            onChange={handleSelectChange(setSecondSpeaker)}
            options={membersList}
            placeholder="Second speaker"
            styles={memberSelectStyles}
            components={{ Option: CustomOption }}
          />
          <Select
            value={thirdSpeaker}
            onChange={handleSelectChange(setThirdSpeaker)}
            options={membersList}
            placeholder="Third speaker"
            styles={memberSelectStyles}
            components={{ Option: CustomOption }}
          />
        </>
      )}

      <button 
        onClick={() => toPDF()}
        className="download-button"
      >
        Download PDF
      </button>

      <div 
        ref={targetRef} 
        className="page-preview"
        style={{ maxWidth: "800px", margin: "0", textAlign: "left" }}
      >
        <h1>
          Sacrament meeting - {date ? format(date, "EEE MMM d") : ""}
        </h1>

        <div className="section-header">Welcome</div>
        <p>Welcome visitors.</p>
        <p>{presiding ? formatName(presiding.label) : '_____'} is presiding today and {conducting ? formatName(conducting.label) : '_____'} is conducting.</p>
        <p>We'd also like to recognize {highCouncilor || '_____'} from the stake high council who is on the stand.</p>
        <p>We are grateful to {chorister ? formatName(chorister.label) : "_____"} and {accompanist ? formatName(accompanist.label) : "_____"} for providing our music today.</p>
        <p>Opening Hymn: {openingHymn?.label || '_____'}</p>
        <p>Invocation: {invocation?.label || '_____'}</p>

        {newMembers.length > 0 && (
          <>
            <div className="section-header">New Members</div>
            <p>We have received the records of the following people. If you could please stand as your names are read so that we can see who you are.</p>
            <table style={{ width: "600px", borderCollapse: "collapse", marginBottom: "10px" }}>
              <thead>
                <tr>
                  <th style={{ width: "100%", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa", textAlign: "left" }}>Name</th>
                </tr>
              </thead>
              <tbody>
                {newMembers.map((newMember, index) => (
                  <tr key={index}>
                    <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(newMember.name)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>All those who can welcome them into the ward, please show by the uplifted hand.</p>
          </>
        )}

        {releases.length > 0 && (
          <>
            <div className="section-header">Releases</div>
            <p>The following have been released and we propose they be given a vote of thanks for their services. (No need to stand on this one)</p>
            <table style={{ width: "600px", borderCollapse: "collapse", marginBottom: "10px" }}>
              <thead>
                <tr>
                  <th style={{ width: "300px", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa", textAlign: "left" }}>Name</th>
                  <th style={{ width: "300px", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa", textAlign: "left" }}>Calling</th>
                </tr>
              </thead>
              <tbody>
                {releases.map((release, index) => (
                  <tr key={index}>
                    <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(release.member_name)}</td>
                    <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{release.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>Those who wish to express their appreciation may manifest it by the uplifted hand.</p>
          </>
        )}

        {sustainings.length > 0 && (
          <>
            <div className="section-header">Sustainings</div>
            <p>We have called the following individuals and propose that they be sustained. Please stand when your name is read and remain standing until the vote is taken.</p>
            <table style={{ width: "600px", borderCollapse: "collapse", marginBottom: "20px" }}>
              <thead>
                <tr>
                  <th style={{ width: "300px", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa", textAlign: "left" }}>Name</th>
                  <th style={{ width: "300px", padding: "8px", border: "1px solid #B0B5C0", backgroundColor: "#f8f9fa", textAlign: "left" }}>Calling</th>
                </tr>
              </thead>
              <tbody>
                {sustainings.map((sustaining, index) => (
                  <tr key={index}>
                    <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{formatName(sustaining.recommended_name)}</td>
                    <td style={{ padding: "8px", border: "1px solid #B0B5C0" }}>{sustaining.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>All in favor, please manifest it by the uplifted hand. Those opposed, if any, may manifest it by the same sign.</p>
            <p>Thank you. If you haven't been set apart for your calling, please see us after the 2nd Hour by the Bishop's office.</p>
          </>
        )}

        <div className="section-header">Stake Business</div>

        <div className="section-header">Program</div>
        <p>Sacrament Hymn: {sacramentHymn?.label || '_____'}</p>
        {!isFastSunday && (
          <>
            <p>First Speaker: {firstSpeaker ? formatName(firstSpeaker.label) : '_____'}</p>
            {!thirdSpeaker && intermediateHymn && <p>Intermediate Hymn: {intermediateHymn.label}</p>}
            <p>Second Speaker: {secondSpeaker ? formatName(secondSpeaker.label) : '_____'}</p>
            {thirdSpeaker && (
              <>
                {intermediateHymn && <p>Intermediate Hymn: {intermediateHymn.label}</p>}
                <p>Third Speaker: {formatName(thirdSpeaker.label)}</p>
              </>
            )}
          </>
        )}

        <p>Thank you to those who have participated in this meeting today. We'll end this meeting by singing hymn number {closingHymn?.label || '_____'} and {benediction?.label || '_____'} will give our closing prayer.</p>

        <p>Closing Hymn: {closingHymn?.label || '_____'}</p>
        <p>Benediction: {benediction?.label || '_____'}</p>
      </div>
    </div>
  );
};