import React from 'react';

export const NoProfilePicture = ({ styles={ width: '100%', height: '100%' }, rounded=false }) => {
  return (
    <svg style={styles} viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      {rounded ? (
        <circle cx="64" cy="64" r="64" fill="#E5E7EB"/>
      ) : (
        <rect x="0" y="0" width="128" height="128" rx="4" fill="#E5E7EB"/>
      )}
      
      <path d="
        M64 68
        C77.25 68 88 57.25 88 44
        C88 30.75 77.25 20 64 20
        C50.75 20 40 30.75 40 44
        C40 57.25 50.75 68 64 68
        Z
        M64 76
        C49.33 76 20 83.33 20 98
        V108
        H108
        V98
        C108 83.33 78.67 76 64 76
        Z" 
        fill="#9CA3AF"/>
    </svg>
  )
}