import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { formatName, isTextOverflowing } from "./utils";
import React, { useState, useRef } from "react";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling

export const MemberWithCalling = ({ member, zoomedOut, unitDetails, sessionInfo, onRelease, onUpdateSetApart, defaultBorderColor = false, membersList, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPendingRelease, setIsPendingRelease] = useState(member.is_pending_release);
  const [showDetails, setShowDetails] = useState(false);
  const nameRef = useRef(null);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  const name = formatName(member.member_name)
  const sustainedDate = new Date(member.sustained_date);
  const monthsSinceSustained = member.months_since_sustained;

  let borderColor = "#B0B5C0";
  if (!defaultBorderColor) {
    if (isPendingRelease && (sessionInfo.accessLevel === "BISHOPRIC")) {
      borderColor = "#ea4335"
    } else if (monthsSinceSustained >= unitDetails.longer_tenure_months) {
      borderColor = "#a33b7d";
    } else if (monthsSinceSustained >= unitDetails.shorter_tenure_months) {
      borderColor = "#d988bb";
    }
  }

  const handleReleaseConfirm = () => {
    setIsPendingRelease(!isPendingRelease)
    onRelease(member.calling_id, !isPendingRelease);
  };

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${borderColor}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "150px",
              width: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {member.notes?.length > 0 && (
              <Tippy content={member.notes.map(n => n.note).join(" • ")} theme="lcrplus">
                <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
              </Tippy>
            )}
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo"/> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)}>
            {name}
          </div>
          <div
            style={{
              fontSize: "13px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontStyle: "italic",
              width: "150px",
            }}
          >
            {member.position}
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `3px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "-1px", right: "-7px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "22px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {!isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-user-minus" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Despedida del llamamiento" : "Release from calling"}
              </DropdownMenuRadioItem>)}
              {isPendingRelease && (<DropdownMenuRadioItem
                className='dropdown-item'
                onClick={handleReleaseConfirm}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-undo" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar estado de despedida" : "Clear released status"}
              </DropdownMenuRadioItem>)}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "210px",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {member.notes?.length > 0 && (
            <Tippy content={member.notes.map(n => n.note).join(" • ")} theme="lcrplus">
              <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
            </Tippy>
          )}
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo"/> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{name}</strong>
        </div>
        {isTextOverflowing(nameRef) ? (
          <Tippy content={member.position} theme="lcrplus">
            <div
              ref={nameRef}
              style={{
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "200px",
                fontStyle: "italic",
              }}
            >
              {member.position}
            </div>
          </Tippy>
        ) : (
          <div
            ref={nameRef}
            style={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "200px",
              fontStyle: "italic",
            }}
          >
            {member.position}
          </div>
        )}
        <div style={{ fontSize: "14px", display: "flex", gap: "8px" }}>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Sostenido:" : "Sustained:"}</strong>
            </div>
            <div>{format(sustainedDate, "MM/dd/yy")}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Apartado:" : "Set apart:"}</strong>
            </div>
            <div>
              {member.is_set_apart ? (
                <i
                  className="bx bx-check"
                  style={{ fontSize: "22px" }}
                ></i>
              ) : (
                <i
                  className={member.is_pending_set_apart ? "bx bx-checkbox-checked" : "bx bx-checkbox"}
                  style={{ 
                    fontSize: "24px", 
                    cursor: "pointer" 
                  }}
                  onClick={() => onUpdateSetApart(member.calling_id, !member.is_pending_set_apart)}
                ></i>
                )}
            </div>
          </div>
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </>
  );
};