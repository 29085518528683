import { useState } from "react";

export const FlashcardsLeaderboard = ({ isOpen, onClose }) => {
  const [featureImage, setFeatureImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  if (!isOpen) return null;

  // Fetch image if not already loaded
  if (!featureImage) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/flashcards-leaderboard.png')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setFeatureImage(imageUrl);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoading(false);
      });
  }

  if (isLoading) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>🏆 Flashcards Leaderboard 🏆</h2>
        <br/>
        <p>Compete anonymously with leaders from your own and other Utah YSA wards!</p>
        <br/>
        {featureImage && <img src={featureImage} style={{ width: '100%', margin: '20px 0' }} />}
      </div>
    </div>
  );
};