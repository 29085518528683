import { useState } from "react";

export const ActivityLevel = ({ isOpen, onClose }) => {
  const [image, setImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(true);

  if (!isOpen) return null;

  // Fetch image if not already loaded
  if (!image) {
    fetch(`/api/image?url=${encodeURIComponent('https://storage.googleapis.com/lcrplus-images/feature_modals/activity-level.gif')}`)
      .then(res => {
        if (res.ok) return res.blob();
        throw new Error('Failed to fetch image');
      })
      .then(blob => {
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl);
        setIsLoadingImage(false);
      })
      .catch(error => {
        console.error(`Error fetching photo:`, error);
        setIsLoadingImage(false);
      });
  }

  if (isLoadingImage) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ textAlign: 'left', position: 'relative', maxWidth: '600px' }}>
        <div
          style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px", zIndex: 20000 }}
          onClick={onClose}
        >
          <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
        </div>
        <h2 style={{ textAlign: 'center' }}>Activity levels</h2>
        <br/>
        <p>You can set the activity level of a member from the "Member reports" page.</p>
        <br/>
        <p>🟢 Active</p>
        <p>🟡 Less Active</p>
        <p>🔴 Inactive</p>
        <br/>
        <p><i>Note: activity levels are reset when the member moves to a new ward.</i></p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {image && <img src={image} style={{ width: '100%', maxWidth: '580px', border: '3px solid #ddd', borderRadius: '4px', objectFit: 'contain' }} />}
        </div>
      </div>
    </div>
  );
};