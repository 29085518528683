import { MemberEventsInferredFromNotes } from "./MemberEventsInferredFromNotes";
import { MovedOutField } from "./MovedOutField";
import { Attendance } from "./Attendance";
import { FlashcardsLeaderboard } from "./FlashcardsLeaderboard";
import { ActivityLevel } from "./ActivityLevel";
import { CallingGroupChats } from "./CallingGroupChats";
import { MinisteringGroupChats } from "./MinisteringGroupChats";
import { NewCallingColumns } from "./NewCallingColumns";

export const FeatureModal = ({ featureModalKey, isOpen, onClose, unitDetails }) => {
  if (featureModalKey === "member_events_inferred_from_notes") {
    return <MemberEventsInferredFromNotes isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "moved_out_field") {
    return <MovedOutField isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "attendance") {
    return <Attendance isOpen={isOpen} onClose={onClose} unitDetails={unitDetails} />;
  } else if (featureModalKey === "activity_level") {
    return <ActivityLevel isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "calling_group_chats") {
    return <CallingGroupChats isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "ministering_group_chats") {
    return <MinisteringGroupChats isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "new_calling_columns") {
    return <NewCallingColumns isOpen={isOpen} onClose={onClose} />;
  } else if (featureModalKey === "flashcards_leaderboard") {
    return <FlashcardsLeaderboard isOpen={isOpen} onClose={onClose} />;
  }
}
