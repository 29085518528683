import { useState} from "react";
import { formatName } from "./utils";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

export const PrioritizedCard = ({ member, zoomedOut, removePrioritized, sessionInfo, unitDetails, membersList, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: "3px solid #B0B5C0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "150px",
              width: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {member.notes?.length > 0 && (
              <Tippy content={member.notes.map(n => n.note).join(" • ")} theme="lcrplus">
                <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
              </Tippy>
            )}
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(member.name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)} style={{marginTop: "16px"}}>
            {formatName(member.name)}
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: "3px solid #B0B5C0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "-1px", right: "-7px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "22px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              <DropdownMenuRadioItem
                className='dropdown-item'
                onClick={() => removePrioritized(member.id)}
                style={{ fontSize: "16px" }}
              >
                <i className="bx bx-trash" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                {unitDetails.language === "es" ? "Eliminar" : "Delete"}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "210px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {member.notes?.length > 0 && (
            <Tippy content={member.notes.map(n => n.note).join(" • ")} theme="lcrplus">
              <CommentRoundedIcon style={{ color: "#99A0AE", fontSize: "16px", position: "absolute", right: "0px", top: "0px", borderBottomLeftRadius: "4px", backgroundColor: "white", paddingLeft: "1.5px" }} />
            </Tippy>
          )}
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={formatName(member.name)} className="member-photo" /> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            width: "200px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "8px",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onClick={() => setShowDetails(true)}
          >
            <strong>{formatName(member.name)}</strong>
          </div>
          <div style={{ fontSize: "14px" }}>
            <div> 
              <strong>{unitDetails.language === "es" ? "Prioritizado por:" : "Prioritized by:"}</strong>
            </div>
            <div>{member.prioritizer_title}</div>
          </div>
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} membersList={membersList} setMembersList={setMembersList} />}
    </>
  );
};