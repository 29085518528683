import React, { useEffect, useRef } from 'react';
import { LogoHorizontal } from './LogoHorizontal';
import { formatName } from './utils';
import { NoProfilePicture } from './NoProfilePicture';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import CloseIcon from '@mui/icons-material/Close';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import SwitchAccountRoundedIcon from '@mui/icons-material/SwitchAccountRounded';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QueueMusicRoundedIcon from '@mui/icons-material/QueueMusicRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const Menu = ({ sessionInfo, unitDetails, activeTab, handleTabSelection, isOpen, onClose }) => {
  const drawerRef = useRef(null);

  // Close drawer if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && <div className="overlay" onClick={onClose} />}
      <div className={`side-drawer ${unitDetails.language} ${isOpen ? 'open' : ''}`} ref={drawerRef}>
        <button
          className="close-btn"
          onClick={onClose}
          style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
        >
          <CloseIcon sx={{ fontSize: 18 }} />
        </button>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 1 auto',
        }}>
          <div style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <div style={{ 
              marginBottom: '8px',
              marginTop: '20px',
              marginLeft: '4px',
            }}>
              <LogoHorizontal scale="1.5" />
            </div>
            <hr
              style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                width: "220px",
                marginBottom: "20px",
              }}
            />
            <div
              style={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
              }}
            >
              {sessionInfo.accessLevel === "BISHOPRIC" &&
                <button
                  className={`dropdown-item ${activeTab === "home" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("home");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "home" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "home" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "home" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <PendingRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Llamamientos pendientes" : "Pending callings"}
                </button>
              }
              {["EQ_PRESIDENCY", "RS_PRESIDENCY", "SS_PRESIDENCY"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`dropdown-item ${activeTab === "myOrganization" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("myOrganization");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "myOrganization" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "myOrganization" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "myOrganization" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <HomeRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Mi organización" : "My organization"}
                </button>
              }
              <button
                className={`dropdown-item ${activeTab === "callingsByOrganization" ? "selected" : ""}`}
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    handleTabSelection("callingsByOrganization");
                  }, 100);
                }}
                style={{ width: "100%", backgroundColor: activeTab === "callingsByOrganization" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "callingsByOrganization" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "callingsByOrganization" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
              >
                <DeviceHubRoundedIcon sx={{ fontSize: 18 }} />
                {unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}
              </button>
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "WARD_MISSION"].includes(sessionInfo.accessLevel) &&
                <>
                  <button
                    className={`dropdown-item ${activeTab === "memberReports" ? "selected" : ""}`}
                    onClick={() => {
                      onClose();
                      setTimeout(() => {
                        handleTabSelection("memberReports");
                      }, 100);
                    }}
                    style={{ width: "100%", backgroundColor: activeTab === "memberReports" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "memberReports" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "memberReports" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <PeopleAltRoundedIcon sx={{ fontSize: 18 }} />
                    {unitDetails.language === "es" ? "Informes de miembros" : "Member reports"}
                  </button>
                </>
              }
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "COMMUNICATION"].includes(sessionInfo.accessLevel) && unitDetails.communication_enabled &&
                <button
                  className={`dropdown-item ${activeTab === "communication" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("communication");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "communication" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "communication" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "communication" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <SmsRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Comunicación" : "Communication"}
                </button>
              }
              {(sessionInfo.isWelcomeNotificationRecipient || sessionInfo.isTithingDeclarationNotificationRecipient || sessionInfo.isTempleRecommendNotificationRecipient || sessionInfo.isSettingApartNotificationRecipient || sessionInfo.isPreviousBishopNotificationRecipient || sessionInfo.isNextBishopNotificationRecipient || sessionInfo.isOnboardingNotificationRecipient || sessionInfo.isBirthdayNotificationRecipient || sessionInfo.isMinisteringNotificationRecipient) &&
                <button
                  className={`dropdown-item ${activeTab === "messageTemplates" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("messageTemplates");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "messageTemplates" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "messageTemplates" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "messageTemplates" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <TextSnippetRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Plantillas de mensajes" : "Message templates"}
                </button>
              }
              <button
                className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    handleTabSelection("ministering");
                  }, 100);
                }}
                style={{ width: "100%", backgroundColor: activeTab === "ministering" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "ministering" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "ministering" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
              >
                <VolunteerActivismRoundedIcon sx={{ fontSize: 18 }} />
                {unitDetails.language === "es" ? "Ministerio" : "Ministering"}
              </button>
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY", "WARD_MISSION"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`dropdown-item ${activeTab === "roommates" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("roommates");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "roommates" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "roommates" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "roommates" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <HomeRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Compañeros de cuarto" : "Roommates"}
                  {/* <span style={{ marginLeft: "32px", fontSize: "12px", fontWeight: "600", color: "white", backgroundColor: "#22c55e", borderRadius: "4px", border: "2px", paddingTop: "1px", paddingBottom: "1px", paddingLeft: "4px", paddingRight: "4px" }}>
                    {unitDetails.language === "es" ? "NUEVO" : "NEW"}
                  </span> */}
                </button>
              }
              {["BISHOPRIC", "EQ_PRESIDENCY", "RS_PRESIDENCY"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`dropdown-item ${activeTab === "flashcards" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("flashcards");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "flashcards" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "flashcards" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "flashcards" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <SwitchAccountRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Tarjetas de memoria" : "Flashcards"}
                </button>
              }
              {false && sessionInfo.accessLevel === "BISHOPRIC" &&
                <button
                  className={`dropdown-item ${activeTab === "bishopInterviews" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("bishopInterviews");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "bishopInterviews" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "bishopInterviews" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "bishopInterviews" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <CalendarMonthIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Entrevistas obispo" : "Bishop interviews"}
                </button>
              }
              {false && ["BISHOPRIC", "MUSIC"].includes(sessionInfo.accessLevel) &&
                <button
                  className={`dropdown-item ${activeTab === "sundayProgram" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("sundayProgram");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "sundayProgram" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "sundayProgram" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "sundayProgram" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <PictureAsPdfOutlinedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Programa dominical" : "Sunday program"}
                </button>
              }
              {false && sessionInfo.accessLevel === "MUSIC" &&
                <button
                  className={`dropdown-item ${activeTab === "sundayMusic" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("sundayMusic");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "sundayMusic" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "sundayMusic" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "sundayMusic" ? "#2684FC" : "black", display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <QueueMusicRoundedIcon sx={{ fontSize: 18 }} />
                  {unitDetails.language === "es" ? "Música dominical" : "Sunday music"}
                </button>
              }
              {/* {sessionInfo.accessLevel === "BISHOPRIC" &&
                <DropdownMenuRadioItem
                  value="covenantPath"
                  className={`dropdown-item ${activeTab === "covenantPath" ? "selected" : ""}`}
                  style={{ backgroundColor: "#f5f5f5", color: "#a0a0a0", cursor: "default" }}
                  disabled
                >
                  <i className="bx bxs-direction-right" style={{ marginRight: '5px', fontSize: "16px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Sendero de convenios" : "Covenant path"}
                  <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
                    {unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
                  </span>
                </DropdownMenuRadioItem>
              } */}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
            <hr style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                width: "220px",
            }}/>
            <div style={{ display: "flex", alignItems: "center", padding: "2px", fontSize: '16px', marginTop: "10px", marginBottom: "10px", gap: "10px" }}>
              {(sessionInfo.member_photo || sessionInfo.household_photo) ?
                <img
                  src={sessionInfo.member_photo || sessionInfo.household_photo}
                  alt={sessionInfo.name}
                  style={{ width: "35px", height: "35px", borderRadius: "50%", border: "0.5px solid #B0B5C0", objectFit: "cover" }}
                /> : <NoProfilePicture styles={{ width: "35px", height: "35px" }} rounded={true} />
              }
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span style={{ fontWeight: "bold" }}>{formatName(sessionInfo.name)}</span>
                {sessionInfo.position && <span style={{ fontStyle: "italic", fontSize: "14px", textAlign: "left" }}>{sessionInfo.position}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
